import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import React from 'react';
import ReactDOM from 'react-dom';
import './components/app/index.scss';
import App from './components/app/app';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

const { REACT_APP_SENTRY_DSN, PUBLIC_URL } = process.env;
console.log('PUBLIC_URL', PUBLIC_URL);

if (REACT_APP_SENTRY_DSN) {
    Sentry.init({ dsn: REACT_APP_SENTRY_DSN });
}

ReactDOM.render(
    <BrowserRouter basename={PUBLIC_URL}>
        <App/>
    </BrowserRouter>,
    document.getElementById('root')
);

serviceWorker.unregister();
