import React, { Component } from 'react';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import './app.scss';
import Welcome from '../common/welcome/welcome';
import {
    Alignment,
    Button,
    Classes, Intent,
    Menu,
    MenuDivider,
    MenuItem,
    Navbar,
    NavbarGroup,
    Popover,
    Position, Tag
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import logo from './logo2.png';
import { LoginComponent } from '../common/login/login';
import { observer } from 'mobx-react';
import { UserModel } from '../../models/user-state';
import { UsersResetPasswordComponent } from '../users/reset-password/reset-password';
import { UsersAddComponent } from '../users/add/add';
import { UsersViewComponent } from '../users/view/view';
import { UsersEditComponent } from '../users/edit/edit';
import { MenuItemLink } from '../common/links/menuitem-link';
import { AppNavigator } from '../../models/navigation';
import { CmsBreadcrumbs } from '../common/breadcrumbs/cms-breadcrumbs';
import { ConfigService } from '../../service/config';
import { IntlProvider } from 'react-intl';

@observer
class App extends Component {
    constructor(props: any) {
        super(props);
        this.viewUserPage = this.viewUserPage.bind(this);
        this.logOut = this.logOut.bind(this);
        AppNavigator.clear();
    }

    static getDerivedStateFromError(error: any) {
        return { hasError: true };
    }

    componentDidMount(): void {
        ConfigService.init().then();
    }

    logOut() {
        UserModel.signOut().finally(() => this.forceUpdate());
    }

    viewUserPage() {
        if (UserModel.isUserAuthenticated) {
            return (
                <Redirect
                    to={{ pathname: '/users/' + UserModel.userId + '' }}
                />
            );
        }
    }

    render() {
        document.title = AppNavigator.title;

        return (
            <IntlProvider locale={'en'} >
                <div className="appComponent">
                    <Navbar className={Classes.DARK} fixedToTop={true}>
                        <NavbarGroup align={Alignment.LEFT}>
                            <Navbar.Heading className="hidden-xs logo">
                                <Link to={'/'}>
                                    <img src={logo} alt={'Logo'}/>
                                </Link>
                            </Navbar.Heading>
                            <Navbar.Divider/>
                            <Navbar.Heading className="">
                                <CmsBreadcrumbs
                                    breadcrumbs={AppNavigator.items}
                                />

                            </Navbar.Heading>
                        </NavbarGroup>

                        {this.AuthenticatedMenu()}
                    </Navbar>
                    <div className={'main'}>
                        <Switch>
                            <Route path="/" exact component={Welcome}/>

                            <Route
                                path="/users/auth/reset-password"
                                component={UsersResetPasswordComponent}
                            />
                            <Route
                                path="/users/auth/sign-up"
                                component={UsersAddComponent}
                            />
                            <Route path="/users/auth/sign-in" component={LoginComponent}/>
                            <PrivateRoute
                                path="/users/:userId/edit"
                                component={UsersEditComponent}
                                isAuthenticated={
                                    UserModel.isUserAuthenticated
                                }
                            />
                            <PrivateRoute
                                path="/users/:userId"
                                component={UsersViewComponent}
                                isAuthenticated={
                                    UserModel.isUserAuthenticated
                                }
                            />

                            <Route component={Welcome}/>
                        </Switch>
                    </div>
                </div>
            </IntlProvider>
        );
    }

    AuthenticatedMenu() {
        if (!AppNavigator.online) {
            return <NavbarGroup align={Alignment.RIGHT}>
                <Tag intent={Intent.WARNING}>Offline</Tag>
            </NavbarGroup>;
        }

        if (!UserModel.isUserAuthenticated) {
            return;
        }

        return (
            <NavbarGroup align={Alignment.RIGHT}>

                <Navbar.Divider/>
                <Popover
                    content={
                        <Menu>
                            <MenuItemLink
                                className={Classes.MENU_ITEM}
                                icon={IconNames.LIST_DETAIL_VIEW}
                                text="View"
                                to={'/users/' + UserModel.userId + '/'}
                            />
                            <MenuItemLink
                                className={Classes.MENU_ITEM}
                                icon={IconNames.SETTINGS}
                                text="Settings"
                                to={
                                    '/settings/'
                                }
                            />
                            <MenuDivider/>
                            <MenuItem
                                text="Sign Out"
                                icon={IconNames.LOG_OUT}
                                onClick={() => this.logOut()}
                            />
                        </Menu>
                    }
                    position={Position.BOTTOM_RIGHT}
                >
                    <Button
                        icon={IconNames.USER}
                        rightIcon={IconNames.CARET_DOWN}
                        minimal={true}
                    >
                        <span className="hidden-xs">
                            {UserModel.userName}
                        </span>
                    </Button>
                </Popover>
            </NavbarGroup>
        );
    }
}

// @ts-ignore
function PrivateRoute({ component: Component, isAuthenticated, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
}

export default App;
