import React, { Component, FormEvent } from 'react';
import { Button, FormGroup, H1, InputGroup, Intent } from '@blueprintjs/core';
import './reset-password.scss';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { IPasswordResetFields } from '../../../models/users';
import { withRouter } from 'react-router';
import { Auth } from 'aws-amplify';
import { AppToaster, ErrorToast } from '../../../service/toaster';
import { AppNavigator } from '../../../models/navigation';
import { ButtonLink } from '../../common/links/button-link';

enum ResetPasswordUiState {
    EmailPrompt,
    CodePrompt
}

@observer
class UsersResetPassword extends Component {
    @observable isFormValid = false;
    @observable isFormDisabled = false;
    @observable uiState: ResetPasswordUiState =
        ResetPasswordUiState.EmailPrompt;
    @observable isNewEqOldPassword = false;
    @observable isNewPasswordsMatched = false;
    @observable
    fields: IPasswordResetFields = {
        email: '',
        code: '',
        newPassword: '',
        newPasswordConfirmation: ''
    };

    constructor(props: any) {
        super(props);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount(): void {
        AppNavigator.clear();
        AppNavigator.add('/users/auth/reset-password/', 'Password Reset');
    }

    submit(event: FormEvent) {
        event.preventDefault();
        if (!this.isFormValid || this.isFormDisabled) return;

        this.isFormDisabled = true;
        switch (this.uiState) {
            case ResetPasswordUiState.EmailPrompt:
                Auth.forgotPassword(this.fields.email)
                    .then((data) => {
                        AppToaster.show({
                            intent: Intent.SUCCESS,
                            message:
                                'Code for password reset has been successfully sent!'
                        });
                        this.uiState = ResetPasswordUiState.CodePrompt;
                        this.validate();
                    })
                    .catch(ErrorToast())
                    .finally(() => (this.isFormDisabled = false));
                break;
            case ResetPasswordUiState.CodePrompt:
                Auth.forgotPasswordSubmit(
                    this.fields.email,
                    this.fields.code,
                    this.fields.newPassword
                    )
                    .then((data) => {
                        console.log(data);
                    })
                    .catch(ErrorToast())
                    .finally(() => (this.isFormDisabled = false));
                break;
        }
    }

    render() {
        return (
            <div>
                <H1>Reset Password</H1>
                {this.renderUi()}
            </div>
        );
    }

    handleFieldChange(event: FormEvent<HTMLInputElement>) {
        this.fields[event.currentTarget.name] = event.currentTarget.value;
        this.validate();
    }

    validate() {
        switch (this.uiState) {
            case ResetPasswordUiState.EmailPrompt:
                this.isFormValid =
                    this.fields.email.length > 3 &&
                    this.fields.email.includes('@');
                break;
            case ResetPasswordUiState.CodePrompt:
                this.isNewPasswordsMatched =
                    this.fields.newPassword ===
                    this.fields.newPasswordConfirmation;
                this.isFormValid =
                    this.fields.code.length > 1 &&
                    this.fields.newPassword.length > 1 &&
                    this.isNewPasswordsMatched;
                break;
        }
    }

    renderUi() {
        switch (this.uiState) {
            case ResetPasswordUiState.EmailPrompt:
                return (
                    <div>
                        <p>Enter your email to get password reset instructions.</p>
                        <form onSubmit={this.submit}>
                            <FormGroup
                                disabled={this.isFormDisabled}
                                label={'E-mail'}
                                labelFor="email-input"
                                labelInfo={'(required)'}
                            >
                                <InputGroup
                                    id="email-input"
                                    name={'email'}
                                    type={'email'}
                                    placeholder="first.last@analog.com"
                                    disabled={this.isFormDisabled}
                                    required={true}
                                    value={this.fields.email}
                                    onChange={this.handleFieldChange}
                                />
                            </FormGroup>
                            <FormGroup inline={true}>
                                <ButtonLink to={'/'}>Cancel</ButtonLink>
                                &nbsp;
                                <Button
                                    disabled={
                                        !this.isFormValid || this.isFormDisabled
                                    }
                                    intent="primary"
                                    text="Reset"
                                    type={'submit'}
                                />
                            </FormGroup>
                        </form>
                    </div>
                );
            case ResetPasswordUiState.CodePrompt:
                return (
                    <div>
                        <form onSubmit={this.submit}>
                            <FormGroup
                                disabled={this.isFormDisabled}
                                label={'Confirmation code'}
                                labelFor="code-input"
                                labelInfo={'(required)'}
                            >
                                <InputGroup
                                    id="code-input"
                                    name={'code'}
                                    type={'code'}
                                    placeholder="Code from email or SMS"
                                    disabled={this.isFormDisabled}
                                    required={true}
                                    value={this.fields.code}
                                    onChange={this.handleFieldChange}
                                />
                            </FormGroup>
                            <FormGroup
                                disabled={this.isFormDisabled}
                                label={'New password'}
                                labelFor="newPassword-input"
                                labelInfo={'(required)'}
                                helperText={
                                    this.isNewEqOldPassword &&
                                    'Passwords cannot be the same.'
                                }
                            >
                                <InputGroup
                                    id="newPassword-input"
                                    name="newPassword"
                                    type={'password'}
                                    placeholder={'Password'}
                                    autoComplete={'new-password'}
                                    minLength={5}
                                    disabled={this.isFormDisabled}
                                    required={true}
                                    value={this.fields.newPassword}
                                    onChange={this.handleFieldChange}
                                />
                            </FormGroup>
                            <FormGroup
                                disabled={this.isFormDisabled}
                                label={'Repeat password'}
                                labelFor="newPasswordConfirmation-input"
                                labelInfo={'(required)'}
                                helperText={
                                    !this.isNewPasswordsMatched &&
                                    'Password must match new password.'
                                }
                            >
                                <InputGroup
                                    id="newPasswordConfirmation-input"
                                    name="newPasswordConfirmation"
                                    type={'password'}
                                    disabled={this.isFormDisabled}
                                    placeholder={'Password'}
                                    minLength={5}
                                    autoComplete={'new-password'}
                                    required={true}
                                    value={this.fields.newPasswordConfirmation}
                                    onChange={this.handleFieldChange}
                                />
                            </FormGroup>
                            <FormGroup inline={true}>
                                <Button
                                    disabled={
                                        !this.isFormValid || this.isFormDisabled
                                    }
                                    intent="primary"
                                    text="Confirm"
                                    type={'submit'}
                                />
                            </FormGroup>
                        </form>
                    </div>
                );
        }
    }
}

export const UsersResetPasswordComponent = withRouter<any, any>(UsersResetPassword);
