import { Auth } from 'aws-amplify';
import { IUser } from '../models/users';

export const Authentication = {
    signUp: async function(user: IUser): Promise<any> {
        return Auth.signUp({
            username: user.email,
            password: user.password || '',
            attributes: mapCognitoUserAttributes(user)
        }).then((r) => {
            console.log(r);
            return r;
        });
    }
};

export function sanitizePhoneNumber(phone: string | undefined) {
    if (!phone) return '';

    const sanePhone = phone.replace(/[^+0-9]/g, '');
    if (sanePhone.length === 10) {
        return '+1' + sanePhone;
    }
    return sanePhone;
}

export function mapCognitoUserAttributes(user: IUser) {
    return {
        email: user.email,
        given_name: user.firstName,
        family_name: user.lastName,
        phone_number: sanitizePhoneNumber(user.phone)
    };
}
