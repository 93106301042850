import { action, observable } from 'mobx';
import { HttpApi } from '../service/http-api';
import { UserModel } from './user-state';
import { ErrorToast, SuccessToast } from '../service/toaster';

export interface IComment {
    id: string;
    firstName: string;
    lastName: string;
    comment: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface IComments {
    items: IComment[];
}

export class CommentsModel {

    constructor() {
        this.handleCommentChange = this.handleCommentChange.bind(this);
        this.add = this.add.bind(this);
    }

    @observable
    comments: IComment[] = [];

    @observable
    comment = '';

    @action
    handleCommentChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        console.log(e);
        this.comment = e.currentTarget.value;
    }

    @action
    async load() {
        /*
        const { items } : IComments = await HttpApi
            .get('https://b1cewrynal.execute-api.us-east-1.amazonaws.com/prod/comments')
            .catch(ErrorToast());

        this.comments = items.sort((a, b) => {
            const da = new Date(a.createdAt);
            const db = new Date(b.createdAt);
            return da.getTime() > db.getTime() ? 1 : -1;
        });

         */
        return [];
    }

    @action
    async add(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        await HttpApi
            .post('https://b1cewrynal.execute-api.us-east-1.amazonaws.com/prod/comments', {
            comment: this.comment,
            given_name: UserModel.user?.firstName,
            family_name: UserModel.user?.lastName,
        })
            .then(() => {
                this.comment = '';
                SuccessToast('Thank you!')('');
            })
            .catch(ErrorToast())
        return this.load();
    }
}