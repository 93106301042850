import React, { Component, FormEvent } from 'react';
import { H1, Intent } from '@blueprintjs/core';
import './edit.scss';
import { IUser } from '../../../models/users';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { UserModel } from '../../../models/user-state';
import { RouteComponentProps, withRouter } from 'react-router';
import { UsersEditForm } from './edit.form';
import { AppToaster, ErrorToast } from '../../../service/toaster';
import { sanitizePhoneNumber } from '../../../service/authentication';
import { UIState } from '../../../types/';
import { StatefulComponent } from '../../common/stateful/stateful';
import { Auth } from 'aws-amplify';
import { AppNavigator } from '../../../models/navigation';

interface IUserProps extends RouteComponentProps<any> {
    userId: string;
}

@observer
class UsersEdit extends Component<IUserProps> {
    @observable
    uiState = UIState.Loading;

    constructor(props: any) {
        super(props);
        this.submit = this.submit.bind(this);
        this.redirect = this.redirect.bind(this);
    }

    redirect() {
        this.props.history.push('/');
    }

    async componentDidMount() {
        AppNavigator.clear();
        try {
            this.uiState = UIState.Loading;
            await UserModel.init();
            const { user } = UserModel;
            if (user) {
                this.uiState = UIState.Loaded;
                AppNavigator.add(`users/${user.id}/edit/`, 'Edit User');
            } else {
                console.log('UserModel.user', UserModel.user);
                this.uiState = UIState.Empty;
            }
        } catch (e) {
            console.log('UsersEdit.componentDidMount', e);
            this.uiState = UIState.Failed;
        }


    }

    public async submit(event: FormEvent) {
        event.preventDefault();
        if (!UserModel.user) {
            return;
        }
        try {
            const currentUser = await Auth.currentAuthenticatedUser({
                bypassCache: true
            });
            if (currentUser) {
                await Auth.updateUserAttributes(
                    currentUser,
                    mapEditCognitoUserAttributes(UserModel.user)
                );
                AppToaster.show({
                    message: `User ${UserModel.user.email} has been updated!`,
                    intent: Intent.SUCCESS
                });
            }
        } catch (e) {
            ErrorToast()(e);
        }
    }

    public render() {
        return (
            <div className="usersEditComponent">
                <H1>Edit</H1>
                <StatefulComponent state={this.uiState}>
                    {this.renderUserForm()}
                </StatefulComponent>
            </div>
        );
    }

    public renderUserForm() {
        const { user } = UserModel;
        if (!user) return;
        return <UsersEditForm
            user={user}
            submit={this.submit}
            isAddMode={false}
        />;
    }
}

export function mapEditCognitoUserAttributes(user: IUser) {
    return {
        given_name: user.firstName,
        family_name: user.lastName,
        phone_number: sanitizePhoneNumber(user.phone)
    };
}

export const UsersEditComponent = withRouter<IUserProps, any>(UsersEdit);
