import { action, computed, observable } from 'mobx';
import { IUser } from './users';
import { CognitoUser } from '@aws-amplify/auth';
import { ucFirst } from '../utils/formatters';
import { ILoginFields } from '../types/login-fields-interface';
import { Auth } from 'aws-amplify';
import { Storage } from '../service/storage';
import * as Sentry from '@sentry/browser';

export function emailToName(email: string) {
    const p = email.split('@');
    return p[0]
        .split(/\W/, 2)
        .map(ucFirst)
        .join(' ');
}

export class UserStateModel {
    @observable isAuthenticated = false;
    @observable changePasswordOnSignIn = false;
    @observable token: string = '';
    @observable tokenExpiresAt: Date = new Date(0);
    @observable user: IUser | undefined = undefined;

    cognitoUser?: CognitoUser;

    @observable
    fields: ILoginFields = {
        email: '',
        password: '',
        code: '',
        newPassword: '',
        newPasswordConfirmation: ''
    };

    @computed
    get userName(): string {
        if (this.isAuthenticated) {
            return this.user ? this.user.firstName : 'Profile';
        }
        return '';
    }

    @computed
    get userId(): string {
        if (this.isAuthenticated) {
            return (this.user && this.user.id) || '';
        }
        return '';
    }

    @computed
    get isUserAuthenticated(): boolean {
        return this.isAuthenticated;
    }

    @action
    async assureCognitoSession() {
        if (!this.cognitoUser) {
            return this.init();
        }
        const session = this.cognitoUser.getSignInUserSession();
        if (!session || !session.isValid()) {
            return this.init();
        }
    }

    @action
    async init() {
        try {
            this.cognitoUser = await Auth.currentAuthenticatedUser({
                bypassCache: false
            });
            console.log('cognitoUser',  this.cognitoUser);
            if (this.cognitoUser) {
                const session = this.cognitoUser.getSignInUserSession();
                console.log('session', session);
                if (session && session.isValid()) {
                    Storage.set(
                        'token',
                        session.getIdToken().getJwtToken()
                    );
                } else {
                    Storage.unset('token');
                }
                await this.setUser(this.cognitoUser);
                this.isAuthenticated = true;
            }
        } catch (e) {
            console.error('Session init error', e);
            this.reset();
        }
        return this.cognitoUser;
    }

    @action
    signIn() {

    }

    @action
    async signOut() {
        return Auth.signOut().finally(() => {
            this.reset();
        });
    }

    @action
    async setUser(user: IUser | CognitoUser): Promise<IUser> {
        console.log('UserState.setUser', user);
        return  new Promise((resolve, reject) => {
            if (user instanceof CognitoUser) {
                user.getUserAttributes((err, attributes) => {
                    console.log('user.getUserAttributes', err, attributes);
                    if (err || !attributes) {
                        reject(err);
                        return;
                    }
                    this.user = {
                        id: extractAttribute(attributes, 'sub') || user.getUsername(),
                        firstName: user.getUsername(),
                        lastName: '',
                        email: extractAttribute(attributes, 'email'),
                        createdAt: new Date(),
                        updatedAt: new Date(),
                        roles: []
                    };

                    const [f, l] =  emailToName(this.user.email).split(' ')

                    this.user.firstName = extractAttribute(
                        attributes,
                        'given_name',
                       f || ''
                    );
                    this.user.lastName = extractAttribute(
                        attributes,
                        'family_name',
                        l || ''
                    );
                    this.user.phone = extractAttribute(
                        attributes,
                        'phone_number',
                        ''
                    );
                    Sentry.setUser({
                        id: this.user.id,
                        email: this.user.email,
                        username: this.user.firstName
                    });
                    this.isAuthenticated = true;
                    Storage.set('submitterId', this.user.id);
                    resolve(this.user);
                });
            } else {
                this.user = user;
                Storage.set('submitterId', this.user.id);
                resolve(this.user);
            }

        });
    }

    @action
    reset() {
        this.tokenExpiresAt = new Date(0);
        this.user = undefined;
        this.token = '';
        this.isAuthenticated = false;
        this.changePasswordOnSignIn = false;
    }

    @action
    async saveUser() {
        if (this.user) {

        }
    }
}

export function extractAttribute(
    attributes: any[],
    name: string,
    defaultValue: string = ''
): string {
    return attributes.reduce((acc, attribute) => {
        return attribute.getName() === name ? attribute.getValue() : acc;
    }, defaultValue);
}

export const UserModel = new UserStateModel();
