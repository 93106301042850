export function localDate(ts: Date | number | string): string {
    const d = new Date(ts);
    return d.toLocaleDateString();
}

export function localTime(ts: Date | number | string): string {
    const d = new Date(ts);
    return d.toLocaleTimeString();
}

export function ucFirst(s: string): string {
    return s[0].toUpperCase() + s.slice(1);
}

export function sanitizeString(s: string): string {
    return s.toLowerCase().replace(/[^a-z]/ig, ' ');
}

export function sanitizeDomain(s: string): string {
    return s.toLowerCase().replace(/[^a-z0-9-.]/ig, '');
}

/**
 * Interpolate string with variables.
 * Works similar to sprintf but missing all the formatting capabilities.
 * Use "%s" to indicate where variable should be inserted.
 *
 * @param {string} template
 * @param {string[]} rest
 */
export function sp(template: string, ...rest: string[]): string {
    return rest.reduce((acc, cv) => {
        return acc.replace('%s', cv);
    }, template);

}
