import React, { Component, FormEvent } from 'react';
import './welcome.scss';
import { Redirect } from 'react-router-dom';
import { UserModel } from '../../../models/user-state';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { AppNavigator } from '../../../models/navigation';
import classNames from 'classnames';
import LoginComponent from '../login/login';
import ReactMarkdown from 'react-markdown';

import greenTeams from '../../pages/green-teams.md';
import resources from '../../pages/resources.md';
import introduction from '../../pages/introduction.md';
import workshopRecording from '../../pages/workshop-recording.md';
import { Button, Callout, Card, Classes, FormGroup, H3, InputGroup, Intent, TextArea } from '@blueprintjs/core';
import { HttpApi } from '../../../service/http-api';
import { AppToaster } from '../../../service/toaster';
import { CommentsModel } from '../../../models/comments';
import { FormattedRelativeTime } from 'react-intl';

// define your Markdown pages here
const mdPages = [
    introduction,
    workshopRecording,
    greenTeams,
    resources
];

@observer
class Welcome extends Component {

    @observable
    isRedirect = false;

    @observable
    isSignUpFormDisabled = false;

    @observable
    mdContent = [''];

    cm: CommentsModel;

    constructor(props: any) {
        super(props);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        mdPages.map(() => this.mdContent.push(''));
        this.cm = new CommentsModel();
    }

    checkAuthState() {
        if (UserModel.isUserAuthenticated || !AppNavigator.online) {
            // this.isRedirect = true;
        }
    }

    componentDidMount(): void {
        AppNavigator.clear();
        AppNavigator.add('/', 'Climate Crisis: Engineering a Sustainable Future');

        this.checkAuthState();
        setTimeout(() => {
            this.checkAuthState();
        }, 1000);

        mdPages.map(async (url, index) => {
            const r = await fetch(url)
            this.mdContent[index] = await r.text();
        })

        try {
            this.cm.load().then();
        } catch (e) {
            console.log(e);
        }
    }

    public render() {
        if (this.isRedirect) {
            return <Redirect to={'/'}/>;
        }

        if (UserModel.isUserAuthenticated) {
            return this.renderPrivate();
        }

        return this.renderPublic();
    }

    public renderPublic() {
        return <div className={classNames('welcomeComponent', 'public')}>
            <div className="welcomeBlock">
                <div className="textBlock">
                    <h1>{AppNavigator.title}</h1>
                    <p> </p>
                    <LoginComponent accountId={''} redirectToReferrer={true}/>
                </div>
            </div>
        </div>
    }

    public renderPrivate() {
        return <div className={classNames('welcomeComponent', 'private')}>
            <div className="mainBlock">

                {this.renderSignUp()}
            {
                this.mdContent.map((t, index) =>
                    <ReactMarkdown
                        key={index}
                        source={t}
                        renderers={{link: props => <a href={props.href}
                         target={
                             props.href.startsWith('http') ? '_blank': 'self'
                         }>{props.children}</a>}} />
                )
            }
                <p>&nbsp;</p>
                {this.renderComments()}
            <p>&nbsp;</p>
            </div>
        </div>
    }

    handleFieldChange(event: FormEvent<HTMLInputElement>) {
        // @ts-ignore
        UserModel.user[event.currentTarget.name] = event.currentTarget.value;
    }

    renderSignUp() {
        return <Card >
            <H3>Signup for mailing list</H3>
            <form action="" onSubmit={(e) => {
                e.preventDefault();
                this.isSignUpFormDisabled = true;
                HttpApi.put('https://b1cewrynal.execute-api.us-east-1.amazonaws.com/prod/signup', {
                    email: UserModel.user?.email
                })
                    .then(() => {
                        AppToaster.show({
                            message: 'Signed Up!',
                            intent: Intent.SUCCESS
                        })
                    })
                    .finally(() => {
                        this.isSignUpFormDisabled = false;
                    })
            }}>
            <div className="flex-row">
                <FormGroup inline={true}
                           disabled={this.isSignUpFormDisabled}
                           label={'E-mail'}
                           labelFor="email-input"
                           labelInfo={'(required)'}
                >
                    <InputGroup
                        fill={true}
                        id="email-input"
                        size={30}
                        name={'email'}
                        type={'email'}
                        placeholder="first.last@analog.com"
                        disabled={this.isSignUpFormDisabled}
                        required={true}
                        onChange={this.handleFieldChange}
                        value={UserModel.user?.email}
                    />
                </FormGroup>
                <FormGroup >
                    <Button
                        disabled={this.isSignUpFormDisabled}
                        intent="primary"
                        text="Sign Up"
                        type={'submit'}
                    />
                </FormGroup>
            </div>
            <sup>No spam; newsletter every 2-4 weeks.</sup>

            </form>
        </Card>
    }

    renderComments() {
        const { comments } = this.cm;

        return <div className="comments">

            {
                comments.map((c) => <div className="comment">
                    <div className="commentHeader">
                        <div className="author">{c.firstName} {c.lastName}</div>
                        <div className="date">{relativeDate(c.createdAt)}</div>
                    </div>
                    <div className="commentBody">
                        <ReactMarkdown
                            key={c.id}
                            source={c.comment}
                            renderers={{link: props => <a href={props.href}
                                                          target={
                                                              props.href.startsWith('http') ? '_blank': 'self'
                                                          }>{props.children}</a>}} />

                    </div>
                </div>
                )
            }

            <Callout title={'Leave a feedback for us'}>
                <p>It won't be shown to anybody.</p>
                <form onSubmit={this.cm.add}>
                    <TextArea
                        fill={true}
                        growVertically={true}
                        large={false}
                        intent={Intent.PRIMARY}
                        onChange={this.cm.handleCommentChange}
                        value={this.cm.comment}
                    />
                    <p>

                    <Button type={'submit'} intent={Intent.PRIMARY}>
                        Send
                    </Button>
                    </p>
                </form>

            </Callout>
        </div>
    }
}


function relativeDate(d: Date) {
    let ts;
    ts = new Date(d);
    const v = (ts.getTime() - Date.now()) / 1000;
    return <span className={(v < -3600 * 24) ? Classes.TEXT_MUTED : 'text-success'}>
        <FormattedRelativeTime value={v} unit={'second'} numeric={'auto'} updateIntervalInSeconds={60}/>
    </span>;
}

export default Welcome;
