import { observer } from 'mobx-react';
import React, { Component, FormEvent } from 'react';
import { observable } from 'mobx';
import { Button, FormGroup, InputGroup, Intent, MenuItem } from '@blueprintjs/core';
import { ButtonLink } from '../../common/links/button-link';

import { IUser, Role } from '../../../models/users';
import { ItemRenderer } from '@blueprintjs/select';

export interface IEditForm {
    user: IUser;
    isAddMode: boolean;
    submit: (event: FormEvent) => void;
}

@observer
export class UsersEditForm extends Component<IEditForm> {
    @observable isFormDisabled = false;
    @observable isFormValid = true;

    constructor(props: any) {
        super(props);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.roleSelected = this.roleSelected.bind(this);
        this.handleTagRemove = this.handleTagRemove.bind(this);
    }

    handleFieldChange(event: FormEvent<HTMLInputElement>) {
        console.log(this);
        // @ts-ignore
        this.props.user[event.currentTarget.name] = event.currentTarget.value;
        this.validate();
    }

    validate() {
    }

    public render() {
        return (
            <form onSubmit={this.props.submit}>
                <FormGroup
                    disabled={this.isFormDisabled}
                    label="First Name"
                    labelFor="firstName-input"
                    labelInfo="(required)"
                >
                    <InputGroup
                        autoFocus={true}
                        id="firstName-input"
                        name="firstName"
                        type="text"
                        placeholder="First Name"
                        minLength={1}
                        disabled={this.isFormDisabled}
                        required={true}
                        value={this.props.user.firstName}
                        onChange={this.handleFieldChange}
                    />
                </FormGroup>
                <FormGroup
                    disabled={this.isFormDisabled}
                    label="Last Name"
                    labelFor="lastName-input"
                    labelInfo="(required)"
                >
                    <InputGroup
                        id="lastName-input"
                        name="lastName"
                        type="text"
                        placeholder="LastName"
                        minLength={1}
                        disabled={this.isFormDisabled}
                        required={true}
                        value={this.props.user.lastName}
                        onChange={this.handleFieldChange}
                    />
                </FormGroup>
                <FormGroup
                    disabled={this.isFormDisabled}
                    label="Phone Number"
                    labelFor="phone-input"
                    labelInfo="(optional)"
                    helperText={'Please, include country code if your phone outside US. +1 will' +
                    ' be used automatically if it is not specified.'}
                >
                    <InputGroup
                        id="phone-input"
                        name="phone"
                        type="phone"
                        placeholder="+1(234)567-8901"
                        minLength={0}
                        disabled={this.isFormDisabled}
                        required={false}
                        value={this.props.user.phone}
                        onChange={this.handleFieldChange}
                    />
                </FormGroup>
                <FormGroup
                    disabled={this.isFormDisabled}
                    label="E-mail"
                    labelFor="email-input"
                    labelInfo="(required)"
                >
                    <InputGroup
                        id="email-input"
                        name="email"
                        type="email"
                        placeholder="email"
                        minLength={1}
                        disabled={this.isFormDisabled || !this.props.isAddMode}
                        required={true}
                        value={this.props.user.email}
                        onChange={this.handleFieldChange}
                    />
                </FormGroup>

                {this.props.isAddMode ? (
                    <FormGroup
                        disabled={this.isFormDisabled}
                        label="Password"
                        labelFor="password-input"
                        labelInfo="(required)"
                    >
                        <InputGroup
                            id="password-input"
                            name="password"
                            type="password"
                            placeholder="password"
                            autoComplete="new-password"
                            minLength={5}
                            disabled={this.isFormDisabled}
                            required={true}
                            value={this.props.user.password}
                            onChange={this.handleFieldChange}
                        />
                    </FormGroup>
                ) : (
                    ''
                )}
                <FormGroup inline={true}>
                    <ButtonLink
                        disabled={this.isFormDisabled}
                        intent={Intent.NONE}
                        to={'/'}
                    >
                        Cancel
                    </ButtonLink>
                    &nbsp;
                    <Button
                        disabled={!this.isFormValid || this.isFormDisabled}
                        intent={Intent.PRIMARY}
                        text={this.props.isAddMode ? 'Sign Up' : 'Save'}
                        type="submit"
                    />
                </FormGroup>
            </form>
        );
    }

    private renderTag = (role: Role) => role;

    private handleTagRemove(role: string, index: number) {
        this.roleSelected(role as Role);
    }

    private roleSelected(role: Role) {
        if (!this.props.user.roles) {
            this.props.user.roles = [];
        }
        if (this.props.user.roles.includes(role)) {
            this.props.user.roles = this.props.user.roles.filter((fr) => {
                return fr !== role;
            });
        } else {
            this.props.user.roles.push(role);
        }
    }

    private isRoleSelected(role: Role): boolean {
        return this.props.user.roles && this.props.user.roles.includes(role);
    }

    private renderRole: ItemRenderer<Role> = (
        role,
        { modifiers, handleClick }
    ) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        return (
            <MenuItem
                active={modifiers.active}
                icon={this.isRoleSelected(role) ? 'tick' : 'blank'}
                key={role}
                label={role}
                onClick={handleClick}
                text={`${role}`}
                shouldDismissPopover={false}
            />
        );
    };
}
